/* Background pattern from Toptal Subtle Patterns */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Taviraj&display=swap');

body {
    background-image: url(./bg.png);
    background-repeat: repeat;
    font-family: 'Poppins', sans-serif;
}

h1 {
    color: #ededed;
    text-align: center;
    font-weight: 600;
}

h2, h3, h4, h5, h6, input, select {
    text-align: center;
    color: #ededed;
    font-weight: bold;
}

input.form-control, select.form-control {
    font-weight: bold;
    color: #323232;
}

.card h2, .card h3, .card h4, .card h5, .card h6 {
    color: #323232;
}

.card-title {
    font-weight: bold;
}

p {
    font-weight: 600;
}

.tmdb {
    color: #ededed;
}

.starred-in {
    color: #ededed;
}

.alert-success {
    background-image: url(./bg.png);
    background-repeat: repeat;
    color: #ededed;
}

.rounded-circle {
    border: 2px solid #ededed;
}

.card .rounded-circle {
    border: 2px solid #323232;
}


.loading {
    text-align: center;
}

.cursor {
    cursor: pointer;
}

.card img {
    width: auto!important;
    border-radius: 50%;
}

p {
    margin: 0!important;
    padding: 0!important;
    text-align: center;
}

.starred-in p{
    font-size: 16px;
}

.scrolling.starred-in {
    position: sticky;
    top: -1px;
    z-index: 1000;
    background-image: url(./bg.png);
    background-repeat: repeat;
    padding: 1% 0;
    border-bottom: 1px solid #CCC;
}

.new-search-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 31%;
    background: linear-gradient(0deg, #000, #ffffff00 100%) no-repeat;
}

.new-search{
    position: fixed;
    bottom: 1%;
    left: 1%;
    width: 98%!important;
    border: 1px solid #ededed;
    background-image: url(./bg.png);
    background-repeat: repeat;

}

footer {
    margin: 0 0 60px 0;
}

footer p {
    font-size: 10px;
    font-weight: bold;
}

footer span.version {
    text-align: center;
    width: 100%;
    display: block;
    font-weight: bold;
    font-size: 10px;
    color: #ededed;
}